/** @jsx jsx */
//import okaidia from '@theme-ui/prism/presets/prism-okaidia'

export default {
  useCustomProperties: true,
  breakpoints: ['40em', '52em', '64em', '70em'],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  colors: {
    text: `#02012c`,
    background: `#ffffff`,
    primary: `#e8999e`,
    pink: `primary`,
    secondary: `text`,
    green: `#82e8c4`,
    blue: `#8cbaff`,
    yellow: `#fffd8c`,
    accent: `#a29cff`,
    contrastPurple: `#6760c3`,
    purple: `accent`,
    muted: `#fcede8`,
    highlight: `#fcede8`,
    primary15: `hsl(356, 63%, 75%, 15%)`,
    primary50: `hsl(356, 63%, 75%, 50%)`,
    purple15: `hsl(244, 100%, 81%, 15%)`,
    purple50: `hsl(244, 100%, 81%, 50%)`,
    green15: `hsl(159, 69%, 71%, 15%)`,
    green50: `hsl(159, 69%, 71%, 50%)`,
    blue15: `hsl(59, 100%, 77%, 15%)`,
    blue50: `hsl(59, 100%, 77%, 50%)`,
    modes: {
      dark: {
        text: `#ffffff`,
        background: `#02012c`,
        primary: `#e8999e`,
        pink: `primary`,
        secondary: `text`,
        green: `#82e8c4`,
        blue: `#8cbaff`,
        yellow: `#fffd8c`,
        accent: `#6760c3`,
        contrastPurple: `#a29cff`,
        purple: `accent`,
        muted: `#090845`,
        highlight: `#090845`,
      },
    },
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fonts: {
    body: 'Lato, sans-serif',
    heading: 'inherit',
    special: 'Playfair Display',
    monospace: 'Menlo, monospace',
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
    special: 600,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
    special: 1.125,
  },
  letterSpacings: {
    body: '1.2em',
  },
  skipLink: {
    border: `0`,
    clip: `rect(0 0 0 0)`,
    height: `1px`,
    width: `1px`,
    margin: `-1px`,
    padding: `0`,
    overflow: `hidden`,
    position: `absolute`,
    '&:focus': {
      fontSize: [2, 3, 4],
      color: 'text',
      textDecoration: 'none',
      border: 'solid 1px',
      borderColor: 'accent',
      padding: `1rem`,
      position: `fixed`,
      top: `10px`,
      left: `10px`,
      bg: `background`,
      zIndex: `1`,
      width: `auto`,
      height: `auto`,
      clip: `auto`,
    },
  },
  text: {
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
    },
    special: {
      fontFamily: 'special',
      lineHeight: 'special',
      fontWeight: 'special',
      fontStyle: 'italic',
      fontSize: '7.5vw',
      textAlign: 'center',
    },
    sectionHeading: {
      textAlign: 'center',
      display: 'inline-block',
      '::after': {
        content: '""',
        display: 'block',
        width: '75%',
        m: '0 auto',
        borderBottom: '3px solid',
        borderColor: 'accent',
      },
    },
  },
  buttons: {
    colorMode: {
      appearance: 'none',
      display: 'block',
      textAlign: 'center',
      lineHeight: 'inherit',
      textDecoration: 'none',
      fontSize: 'inherit',
      fontWeight: 'bold',
      mr: 1,
      color: 'background',
      bg: 'text',
      borderRadius: '50%',
      border: '1px solid',
      borderColor: 'accent',
    },
  },
  headings: {
    withAccent: {
      display: 'inline-block',
      width: 'fit-content',
      backgroundImage: theme =>
        `linear-gradient(transparent 55%, ${theme.colors.muted} 55%, ${theme.colors.muted} 10%, transparent 95%)`,
      backgroundSize: `100% 1.25em`,
      wordBreak: `keep-all`,
      mr: `-0.5em`,
      backgroundPosition: `0.5em 2px`,
      backgroundRepeat: `no-repeat space`,
    },
    withLine: {
      '::after': {
        content: '""',
        display: 'block',
        width: '100%',
        height: ['0.25em', '0.5em'],
        marginLeft: ['-0.75em', ' -0.5em'],
      },
    },
  },
  posts: {
    article: {
      width: ['90%', '80%', '75%'],
    },
  },
  emojiSpan: {
    p: 2,
    height: '100%',
    width: '100%',
    verticalAlign: 'middle',
  },
  links: {
    menuIcon: {
      ml: 2,
      mb: 2,
      fontSize: [3, 4, 5],
      bg: 'green',
      borderRadius: '50%',
      border: '1px solid',
      borderColor: 'contrastPurple',
      height: '2em',
      width: '2em',
      textAlign: 'center',
      textDecoration: 'none',
      '&:hover, &:focus': {
        borderColor: 'text',
      },
    },
    menu: {
      color: 'text',
      fontWeight: 'bold',
      fontSize: [3, 4, 5],
      textDecoration: 'none',
      ml: [1, null, null],
      mr: [2, 3, 5],
      display: 'inline-block',
      border: '1px solid',
      borderColor: 'transparent',
      '&:hover, &:focus': {
        border: '1px solid',
        borderColor: 'accent',
        color: 'text',
      },
      '&:last-child': {
        mr: [2, 3, 5],
      },
    },
    primary: {
      border: '1px solid accent',
      padding: '10px 50px',
    },
  },
  image: {
    squat: {
      maxWidth: ['100%', '50%'],
      maxHeight: ['100%', '75%'],
      mx: `auto`,
    },
    tall: {
      maxHeight: ['100%', '50%'],
      maxWidth: ['100%', '75%'],
      mx: `auto`,
    },
    outlined: {
      display: 'grid',
      gridTemplateColumns: '1em auto 1em',
      gridTemplateRows: '1em auto 1em',
      '.gatsby-image-wrapper': {
        gridArea: '1 / 1 / -2 / -2',
      },
      '::after': {
        content: '""',
        display: 'block',
        height: '100%',
        width: '100%',
        bg: 'primary',
        gridArea: '2 / 2 / -1 / -1',
      },
    },
  },
  body: {
    maxWidth: '100%',
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      fontSize: [2, 3, 4],
      wordSpacing: '0.1em',
    },
    article: {
      pl: [3, null, null, 5],
      pr: [3, null, null, 5],
      pt: 2,
      pb: 3,
      maxWidth: '80%',
    },
    a: {
      textDecoration: 'none',
      fontWeight: 'bold',
      color: `contrastPurple`,
      border: '2px solid',
      borderColor: 'transparent',
      '&:hover, &:focus': {
        border: '2px solid',
        borderColor: 'green',
        color: `contrastPurple`,
      },
    },
    ul: {
      listStyle: 'none',
      pl: '1em',
      li: {
        position: 'relative',
        '::before': {
          content: '""',
          display: 'block',
          width: '0.4em',
          height: '0.4em',
          borderRadius: '50%',
          bg: 'primary',
          transform: 'translate(-50%, 20%)',
          position: 'absolute',
          top: '0.5em',
          left: '-1em',
        },
        'ul li': {
          '::before': {
            bg: 'green',
          },
          'ul li': {
            '::before': {
              bg: 'blue',
            },
          },
        },
      },
    },
    h1: {
      variant: 'headings.withLine',
      fontSize: [5, 6],
      maxWidth: '100%',
      '::after': {
        borderLeft: '3px solid',
        borderBottom: '3px solid',
        borderColor: 'primary',
        content: '""',
        display: 'block',
        width: ['80%', '100%'],
        height: ['0.25em', '0.5em'],
        marginLeft: ['-0.75em', ' -0.5em'],
      },
    },
    h2: {
      variant: 'headings.withLine',
      fontSize: [4, 5],
      '::after': {
        borderLeft: '3px solid',
        borderBottom: '3px solid',
        borderColor: 'accent',
        content: '""',
        display: 'block',
        width: '90%',
        height: ['0.25em', '0.5em'],
        marginLeft: ['-0.75em', ' -0.5em'],
      },
    },
    h3: {
      fontSize: [3, 4],
      variant: 'headings.withLine',
      '::after': {
        borderLeft: '2px solid',
        borderBottom: '2px solid',
        borderColor: 'green',
        content: '""',
        display: 'block',
        width: '80%',
        height: ['0.25em', '0.5em'],
        marginLeft: ['-0.75em', ' -0.5em'],
      },
    },
    h4: {
      variant: 'headings.withLine',
      fontSize: [2, 3],
      '::after': {
        borderLeft: '2px solid',
        borderBottom: '2px solid',
        borderColor: 'blue',
        content: '""',
        display: 'block',
        width: '75%',
        height: ['0.25em', '0.5em'],
        marginLeft: ['-0.75em', ' -0.5em'],
      },
    },
    h5: {
      variant: 'text.heading',
      fontSize: [1, 2],
    },
    h6: {
      variant: 'text.heading',
      fontSize: [0, 1],
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    img: {
      objectFit: 'scale-down',
      width: '100%',
    },
    pre: {
      fontWeight: 700,
      p: 3,
      borderRadius: '2%',
      fontSize: [2, 3],
    },
  },
}
