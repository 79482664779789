import React from 'react'
import Prism from '@theme-ui/prism'
import { Image } from 'theme-ui'

const components = {
  pre: props => props.children,
  code: Prism,
  img: ({ title, ...props }) => <Image {...props} />, //image sets alt text and title to be the same, which is redundant
}
export default components
