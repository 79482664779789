// gatsby-browser.js

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (prevLocation !== null) {
    const skipLink = document.querySelector('[data-reach-skip-link]') //this is the query selector that comes with the <SkipNavLink> component
    if (skipLink) {
      skipLink.focus()
    }
  }
}
